.root {
  overflow-y: auto;
  overflow-x: hidden;
  flex-grow: 1;
}

.panel_filters {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin: 1rem 1rem 1rem 0.5rem;
}

.reload_button {
  width: 150px;
}

.INFO {
}

.INFO {
}

.WARN {
  color: rgba(200, 200, 20, 0.9);
}

.ERROR {
  color: rgba(200, 20, 20, 0.9);
}

.message {
  display: flex;
  gap: 0.15rem;
  padding: 0 1rem;
}

.time {
  min-width: 190px;
}

.name {
  min-width: 130px;
  color: var(--label-text-color);
}

.text {
  word-break: break-all;
  white-space: pre;
}

mark {
  background-color: #6c5dd3;
}

.searchInput {
  margin: 10px !important;
  width: 500px;
}

.searchIcon {
  opacity: 0.33;
  padding-right: 5px;
}
