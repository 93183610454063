.root {
  display: flex;
  flex-wrap: wrap;
  align-content: stretch;
  height: 100%;
  gap: 30px;
}

.box {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}